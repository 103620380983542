.contact {
  .footer_image {
    margin-top: 0;
  }

  #map {
    height: rem-calc(750);
    width: 100%;

    img[src*=".svg"] {
      width: rem-calc(50);
    }
  }

  .contact_inside {
    .site_footer {
      margin-top: - rem-calc(40);
      padding-top: 0;
      background-color: get-color(secondary);
    }
  }

  .content_grid {
    &.-bottom {
      margin-top: rem-calc(30);

      .content_cell {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .content_cell {
    @include xy-gutters(
            $gutter-position: top bottom
    );

    &.-branches {
      @include xy-grid();
      @include xy-gutters(
              $gutters: 0
      );
      @include xy-grid-layout(
              $n: 1,
              $selector: '.branch',
              $gutter-position: top right bottom left
      );

      @include breakpoint(medium) {
        @include xy-grid-layout(
                $n: 2,
                $selector: '.branch',
                $gutter-position: top right bottom left
        );
      }
    }
  }
}