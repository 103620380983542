.content_offset_cell {
  min-height: rem-calc(530);
  background-color: get-color(secondary);

  @include breakpoint(small only) {
    margin-top: rem-calc(100);
  }

  @include breakpoint(medium) {
    @include xy-cell(
            $size: 11,
            $gutter-type: margin,
            $gutter-position: top right bottom left
    );
    margin-bottom: rem-calc(40);
  }

  &.-left {
    @include breakpoint(medium) {
      @include xy-cell-offset(
              $n: 1
      );
    }

    .image_cell {
      @include breakpoint(medium) {
        @include flex-order(1);
      }

      .portrait_box {
        @include breakpoint(medium) {
          transform: translateX(-75px);
        }

        @include breakpoint(large) {
          transform: translateX(-95px);
        }

        @include breakpoint(xlarge) {
          transform: translateX(-115px);
        }
      }
    }

    .text_cell {
      @include breakpoint(medium) {
        @include flex-order(2);
      }
    }
  }

  &.-right {
    .image_cell {
      @include breakpoint(medium) {
        @include flex-order(2);
      }

      .portrait_box {

        @include breakpoint(medium) {
          transform: translateX(35px);
        }

        @include breakpoint(large) {
          transform: translateX(70px);
        }

        @include breakpoint(xlarge) {
          transform: translateX(90px);
        }
      }
    }

    .text_cell {
      @include breakpoint(medium) {
        @include flex-order(1);
      }
    }
  }
}

.image_cell {
  position: relative;

  @include breakpoint(small only) {
    margin-top: - rem-calc(80);
  }

  @include breakpoint(medium) {
    @include xy-cell(
            $size: 4
    );
  }
}

.text_cell {
  position: relative;
  @include breakpoint(small only) {
    background-color: get-color(secondary);
    @include xy-gutters(
            $gutter-position: left right bottom top
    )
  }

  @include breakpoint(medium) {
    @include xy-cell(
            $size: 7,
            $gutter-type: padding
    );
  }
}

.portrait_box {
  width: rem-calc(220);

  @include breakpoint(medium) {
    position: absolute;
    top: rem-calc(50);
    width: rem-calc(300);
  }

  @include breakpoint(large) {
    width: rem-calc(380);
  }

  @include breakpoint(xlarge) {
    width: rem-calc(420);
  }

  img {
    display: block;
    border-radius: 50%;
  }

}


.text_width {
  @include breakpoint(small only) {
    padding: rem-calc(20);
  }
  @include breakpoint(medium) {
    padding: rem-calc(50 0 100 50);
  }

  h1 {
    font-family: 'juliettaregular';
    font-size: rem-calc(70);
    line-height: 1.2;
    font-weight: 400;
  }
}