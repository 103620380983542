.lead {
  font-weight: 600;
}

.content_container {

  ul:not(.menu) {

    li {
      list-style: none;

      &:before {
        content: '\2022';
        display: inline-block;
        padding-right: rem-calc(6);
        padding-left: rem-calc(6);
        position: absolute;
        left: 0;
      }
    }
  }

  p + ul:not(.menu) {
    margin-top: - rem-calc(18);
  }
}