// Fontawesome
@import url('https://use.fontawesome.com/releases/v5.7.1/css/fontawesome.css');
@import url('https://use.fontawesome.com/releases/v5.7.1/css/brands.css');
@import url('https://use.fontawesome.com/releases/v5.7.1/css/solid.css');

// Webfont
@import url('https://fonts.googleapis.com/css?family=Hind:300,400,600');

@font-face {
  font-family: 'juliettaregular';
  src: url('../fonts/julietta/julietta-webfont.woff2') format('woff2'),
  url('../fonts/julietta/julietta-webfont.woff') format('woff'),
  url('../fonts/julietta/julietta-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}