.gallery {
  @include xy-grid();
}

.gallery_item {
  @include xy-cell(
          $gutters: 0
  );

  @include breakpoint(medium) {
    @include xy-cell(
            $size: 4,
            $gutters: 0
    )
  }

  img {
    width: 100%;
  }
}

.praxis {
  .footer_image {
    margin-top: 0;
  }
}