body, html {
  margin: 0;
  padding: 0;
}

.content_container {
  position: relative;
  @include xy-grid-container();

  &.-full {
    @include xy-grid-container(
            $width: 100%
    )
  }
}

.content_grid {
  position: relative;
  @include xy-grid();
}

.content_cell {
  position: relative;
  @include xy-cell();

  &.-small {
    @include xy-cell(
            $size: 10
    )
  }


  &.-half {
    @include breakpoint(medium) {
      @include xy-cell(
              $size: 6
      );

      &.-small {
        @include xy-cell(
                $size: 6
        )
      }
    }

    @include breakpoint(large) {

      &.-small {
        @include xy-cell(
                $size: 5
        )
      }
    }
  }

  &.-four {
    text-align: left;

    .phone {
      margin-top: 0;
      text-align: left;
    }



    @include breakpoint(large) {
      @include xy-cell(
              $size: 4
      );
      text-align: right;

      .phone{
        text-align: right;
      }
    }
  }

  &.-eight {
    @include breakpoint(large) {
      @include xy-cell(
              $size: 8
      );
    }
  }
}

.termin_booking_trigger {
  @include button-base();
  @include button-hollow();
  color: get-color(primary);
  border-color: get-color(primary);
  text-transform: uppercase;
  line-height: 1;
  height: rem-calc(43);

  &:focus {
    outline: none;
  }
}

.phone {
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  display: inline-block;
  width: 100%;
}

section {
  &.texte,
  &.twotexte,
  &.offers,
  &.team
  &.title {
    @include xy-gutters(
            $gutter-position: bottom,
            $gutters: 60px
    )
  }
}

.reveal {
  select {
    outline: none;
    display: inline-block;
    color: $white;
    cursor: pointer;

    option {
      text-align: center;
    }
  }
}
