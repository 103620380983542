.content_cell {
  &.-two {
    @include breakpoint(medium) {
      @include xy-cell(
              $size: 6
      )
    }
  }

  &.-three {
    @include breakpoint(medium) {
      @include xy-cell(
              $size: 4
      )
    }
  }

  &.-one,
  &.-two,
  &.-three {
    background-color: get-color(secondary);
    text-align: center;
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(50);
    margin-bottom: rem-calc(130);
  }
}

.offers {
  @include flex-align(
          $x: center
  );

  .content_cell {
    @include xy-gutters(
            $gutter-position: bottom
    );

    &.-one,
    &.-two,
    &.-three {
      background-color: get-color(secondary);
      text-align: center;
      padding-top: rem-calc(50);
      padding-bottom: rem-calc(50);
      margin-bottom: rem-calc(130);
    }
  }

  h1 {
    font-size: rem-calc(26);
    line-height: 1.2;

    @include breakpoint(large) {
      font-size: rem-calc(36);
    }
  }
}

.offer_text {
  width: xy-cell-size(10);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.before_duration {
  font-weight: 600;
}

.offer_price {
  position: absolute;
  bottom: 0;
  right: rem-calc(20);
  transform: translateY(70%);
  background-color: mix($white, get-color(primary), 75%);
  width: rem-calc(160);
  height: rem-calc(160);
  border-radius: 50%;
  display: inline-flex;
  @include flex-align(
          $x: center,
          $y: middle
  );
  text-align: center;

  .before_price {
    text-transform: uppercase;
    margin: 0;
    padding: 10px 0;
    line-height: 1;
    font-size: rem-calc(22);
    width: 100%;
    display: block;
  }

  .price {
    font-family: 'juliettaregular';
    font-size: rem-calc(70);
    font-weight: $global-weight-normal;
    margin: 0;
    padding: 0;
    line-height: 1;
    display: block;
    text-indent: 20px;
  }
}