@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.size_container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  @include xy-grid-container(
          $width: 100%,
          $padding: 0
  );
  position: relative;
}

.fullscreen_image {
  width: inherit;
  height: inherit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  animation: scaleAnimation 120s infinite;
}

.home {
  .content_grid {
    height: 100%;
    @include flex-align(
            $x: center,
            $y: middle
    );
    text-align: center;
  }
}

.work_section {
  color: white;
  display: inline-block;
  font-size: rem-calc(24);
  position: relative;
  padding: 0 rem-calc(10);
  text-transform: uppercase;

  span {
    padding: 0;
    line-height: 1.5;
    display: inline-block;
    border-bottom: 1px solid transparent;
    margin-bottom: 2px;
  }

  &:hover {
    color: $white;

    span {
      border-color: $white;
    }
  }

  &:before {
    position: relative;
    content: '\2022';
    bottom: 4px;
    left: -15px;
    width: 5px;
    height: 5px;
    font-size: 1rem;
  }

  &:first-child {
    &:before {
      content: none;
    }
  }

  @media screen and #{breakpoint(small only)} and #{breakpoint(portrait)} {
    &:nth-child(3) {
      &:before {
        content: none;
      }
    }
  }
}