.footer_image {
  .footer_sizer {
    .footer_background {
      width: 100%;
      height: rem-calc(500);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: inline-flex;
      @include flex-align(
              $x: center,
              $y: middle
      );
      text-align: center;

      h3 {
        color: $white;
        padding: 0;
        margin: 0;
        line-height: 1;
      }

      h3:not(.subheader) {
        font-family: 'juliettaregular';
        font-size: rem-calc(55);
        font-weight: 400;
        line-height: 1.1;

        @include breakpoint(medium) {
          font-size: rem-calc(90);
        }
      }

      .subheader {
        font-size: rem-calc(30);
        font-weight: 300;
        text-transform: uppercase;
      }
    }
  }
}


.site_footer {
  padding: rem-calc(100) 0;
  background-color: $white;
  font-size: rem-calc(14);

  @include breakpoint(xlarge) {
    font-size: rem-calc(15);
  }
}

.footer_container {
  @include xy-grid-container();
}

.footer_grid {
  @include xy-grid();
}

.footer_cell {
  @include xy-cell();
  position: relative;

  &.-three {
    @include breakpoint(medium) {
      @include xy-cell(
              $size: 6
      )
    }

    @include breakpoint(large) {
      @include xy-cell(
              $size: 3
      )
    }
  }

  &.-four {
    @include breakpoint(medium) {
      @include xy-cell(
              $size: 6
      )
    }

    @include breakpoint(large) {
      @include xy-cell(
              $size: 4
      )
    }

    &.-infos {
      @include breakpoint(large) {
        margin-left: 0;
      }
    }
  }

  &.-six {
    margin-top: rem-calc(20);

    @include breakpoint(medium) {
      margin-top: 0;
      @include xy-cell(
              $size: 6
      )
    }
  }

  &.-nine {
    margin-top: rem-calc(20);

    @include breakpoint(medium) {
      margin-top: 0;
      @include xy-cell(
              $size: 9
      )
    }
  }

  &.-eight {
    margin-top: rem-calc(20);

    @include breakpoint(medium) {
      margin-top: 0;
      @include xy-cell(
              $size: 12
      )
    }

    @include breakpoint(large) {
      margin-top: 0;
      @include xy-cell(
              $size: 8
      )
    }

    &.-branches {
      margin-bottom: rem-calc(20);
      @include xy-grid();
      @include xy-gutters(
              $gutters: 0
      );
      @include xy-gutters(
              $gutters: 0
      );
      @include xy-grid-layout(
              $n: 1,
              $selector: '.branch',
              $gutter-position: top right bottom left
      );

      @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
        @include xy-grid-layout(
                $n: 2,
                $selector: '.branch',
                $gutter-position: top right bottom left
        );
      }
    }
  }
}

.-branch_grid {
  .-four {
    @include xy-gutters(
            $gutter-position: top bottom
    )
  }
}

#bookingTool {
  outline: none;

  .responsive-embed {
    height: 100%;
  }

  iframe {
    width: 100%;
    height: rem-calc(800);
    max-height: 100%;
  }
}


.site_footer {
  h3 {
    margin: 0;
    padding: 0;
  }

  .address {
    margin: 0;
    padding: 0;
  }

  .website {
    display: inline-block;
    padding-bottom: rem-calc(20);
    width: 100%;
  }

  .copyright {
    display: block;
  }

  .system__nav {
    a {
      float: left;
      display: inline;

      &:before {
        content: '\2022';
        display: inline-block;
        padding-right: rem-calc(6);
        padding-left: rem-calc(6);
      }

      &:first-child {
        &:before {
          content: none;
        }
      }
    }
  }

  .socialmedia_buttons {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-left: -5px;
    margin-bottom: 20px;
    display: block;

    li{
      display: inline-block;
      vertical-align: middle;
      padding: 5px;
    }

    a {
      @include button-base();
      @include button-hollow();
      border-color: get-color(primary);
      margin: 0;

      &[href*="twitter"],
      &[href*="facebook"],
      &[href*="instagram"] {
        text-indent: -99999px;
        display: inline-block;
        position: relative;
        width: rem-calc(40);
        height: rem-calc(40);

        &:before {
          display: block;
          position: absolute;
          font-family: "Font Awesome 5 Brands";
          color: get-color(primary);
          text-indent: 0;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          font-size: rem-calc(20);
        }
      }

      &[href*="twitter"] {
        &:before {
          content: '\f099';
        }
      }

      &[href*="facebook"] {
        &:before {
          content: '\f39e';
        }
      }

      &[href*="instagram"] {
        &:before {
          content: '\f16d';
        }
      }
    }

    .booking_link {
      @include breakpoint(small only) {
        width: 100%;
        display: inline-block;
      }
    }

    .termin_booking_trigger {
      float: left;
      margin: 0;

      @include breakpoint(medium) {
        width: auto;
      }
    }
  }

  .partner_links {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      line-height: 1.1rem;
    }
  }

  .bottom_aligner {
    position: relative;
    width: 100%;
    @include breakpoint(large) {
      bottom: 0;
      position: absolute;
    }
  }
}
