.button_align {
  @include flex-align(
          $x: center,
          $y: middle
  );
  height: 100%;
  display: inline-flex;
}

.news_button {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: mix($white, get-color(primary), 75%);
  border-radius: 50%;
  width: rem-calc(220);
  height: rem-calc(220);
  transform: translateY(rem-calc(45));
  cursor: pointer;
  color: get-color(primary);
  z-index: 80;

  @include breakpoint(medium) {
    z-index: 115;
  }


  @media screen and #{breakpoint(medium down)} and #{breakpoint(landscape)} {
    display: none;
  }

  @include breakpoint(medium) {
    width: rem-calc(350);
    height: rem-calc(350);
    transform: translateY(rem-calc(65));
  }


  @include breakpoint(xxlarge) {
    transform: translateY(rem-calc(65)) translateX(50%);
  }

  button {
    outline: none;
  }

  h3,
  h4 {
    color: get-color(primary);
    line-height: 1;
  }

  h3 {
    font-family: 'juliettaregular', serif;
    font-size: rem-calc(46);
    font-weight: 400;

    @include breakpoint(medium) {
      font-size: rem-calc(70);
    }
  }

  h4 {
    font-weight: 300;
    text-transform: uppercase;
    font-size: rem-calc(14);
    @include breakpoint(medium) {
      font-size: rem-calc(24);
    }
  }
}

.close_button {
  position: absolute;
  top: rem-calc(10);
  left: rem-calc(20);
  cursor: pointer;

  @include breakpoint(medium) {
    top: rem-calc(20);
    left: rem-calc(20);
  }

  @include breakpoint(xlarge) {
    top: rem-calc(100);
    left: rem-calc(100);
  }
}

.offset_news {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: rem-calc(30) 0 0 0;
  background-color: $white;
  transform: translateY(100%);
  transition: transform 400ms ease-in-out;
  z-index: 120;
  overflow: scroll;

  @include breakpoint(medium) {
    top: auto;
    padding: rem-calc(100) 0;
  }

  &.expanded {
    transform: translateY(0);
  }

  .content_grid {
    text-align: left;
  }

  h1 {
    font-family: 'juliettaregular';
    font-weight: 400;
    font-size: rem-calc(38);
    margin-top: rem-calc(40);

    @include breakpoint(large) {
      font-size: rem-calc(60);
      margin-top: 0;
    }
  }
}

.pin_box {
  position: absolute;
  right: rem-calc(20);
  bottom: - rem-calc(20);
  background-color: mix($white, get-color(primary), 75%);
  border-radius: 50%;
  width: rem-calc(100);
  height: rem-calc(100);
  @include flex-align(
          $x: center,
          $y: middle
  );
  text-align: center;

  @include breakpoint(large) {
    right: rem-calc(40);
    bottom: - rem-calc(50);
    width: rem-calc(150);
    height: rem-calc(150);
  }

  h4 {
    font-family: 'juliettaregular';
    font-size: rem-calc(36);
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 1;
    text-transform: none;

    @include breakpoint(large) {
      font-size: rem-calc(50);
    }
  }

  .pin {
    line-height: 1;
    text-transform: uppercase;
    font-size: rem-calc(14);

    @include breakpoint(large) {
      font-size: auto;
    }
  }
}

.news_image {
  @include breakpoint(medium) {
    max-width: 95%;
  }
}

.news_text {
  @include breakpoint(large) {
    max-width: xy-cell-size(9);
    font-size: rem-calc(18);
  }
}

.content_grid {
  &.black {
    .news_image {
      background-color: $black;
    }
  }

  &.primary {
    .news_image {
      background-color: get-color(primary);
    }
  }

  &.secondary {
    .news_image {
      background-color: get-color(secondary);
    }
  }
}
