.menu_container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: get-color(secondary);
  z-index: 110;
  width: 100%;
  height: 100vh;
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms ease-in-out;

  .close_button {
    display: none;
    top: rem-calc(10);
    left: rem-calc(20);
    cursor: pointer;

    @include breakpoint(medium) {
      top: rem-calc(20);
      left: rem-calc(20);
    }

    @include breakpoint(xlarge) {
      top: rem-calc(80);
      left: rem-calc(100);
    }
  }

  &.expanded {
    max-height: 100%;

    .close_button {
      display: block;
    }
  }
}

.menu_trigger {
  padding: 0;
  span {
    position: relative;
    padding-left: rem-calc(25);
    top: 1px;
  }
}

.menu_aligner {
  @include flex-align(
          $x: center,
          $y: middle
  );
  height: 100%;
  width: 100%;
  text-align: center;
  display: inline-flex;

  .menu {

    li{
      &.not_menu{
        margin-top: rem-calc(20);

        @include breakpoint(medium){
          margin-top: rem-calc(40);
          margin-bottom: rem-calc(60);
        }
      }
    }

    li:not(.not_menu) {
      a {
        font-family: 'juliettaregular', cursive;
        font-size: rem-calc(38);
        line-height: 1.2;
        position: relative;
        display: inline-block;

        @include breakpoint(large) {
          font-size: rem-calc(90);
          line-height: 1.2;
        }

        &:after {
          content: url("../img/Navigation-Aktiv-Strich.svg");
          display: block;
          width: rem-calc(50);
          position: absolute;
          top: rem-calc(28);
          left: 50%;
          opacity: 0;
          transform: translateX(-55%);
          //transition: opacity 400ms ease-in-out;


          @include breakpoint(medium){
            width: rem-calc(100);
            top: rem-calc(45);
          }
        }


        &.is-active,
        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}


.home {
  .site_logo {
    display: block;
    right: 0;
  }

  .site_header {
    .menu_trigger,
    .termin_booking_trigger {
      position: absolute;
      z-index: 100;
      cursor: pointer;
      top: rem-calc(20);

      @include breakpoint(large) {
        top: rem-calc(80);
      }
    }

    .menu_trigger {
      color: $white;
      font-weight: 600;
      text-transform: uppercase;
      left: rem-calc(20);

      @include breakpoint(large) {
        left: rem-calc(100);
      }

      &:after {
        background: $white;
        box-shadow: 0 7px 0 $white, 0 14px 0 $white
      }
    }

    .termin_booking_trigger {
      display: block;
      color: $white;
      border-color: $white;
      right: rem-calc(20);

      @include breakpoint(large) {
        right: rem-calc(80);
      }
    }
  }
}

body:not(.home) {
  .site_header {
    background-color: get-color(secondary);
    padding: rem-calc(20) 0;

    @include breakpoint(large) {
      padding: rem-calc(80) 0;
    }
  }
}