.titlebar{
  h3{
    font-family: 'juliettaregular';
    font-weight: 400;
    font-size: rem-calc(70);
    line-height: 1;
    text-align: center;

    @include breakpoint(medium){
      margin-top: rem-calc(100);
    }
  }

  .buttons{
    margin-bottom: rem-calc(60);

    @include breakpoint(medium){
      margin-bottom: rem-calc(100);
    }

    a{
      margin: rem-calc(10);
    }
  }


  .-leftpos{
    text-align: left;
  }

  .-centerpos{
    text-align: center;
  }
}