.praxis,
.contact,
.reveal-overlay,
.standard {
  h2 {
    font-family: 'juliettaregular';
    font-weight: 400;
    font-size: rem-calc(90);

    margin-top: rem-calc(40);
  }

  .site_header {
    .menu_trigger {
      position: relative;
      color: get-color(primary);
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;

      @include breakpoint(large) {
        margin-left: rem-calc(80);
      }
    }

    .site_logo {
      float: right;
      position: relative;
      max-width: rem-calc(160);

      @include breakpoint(large) {
        margin-right: rem-calc(80);
        max-width: rem-calc(320);
      }

      .subheader {
        display: none;
        text-align: center;
        @include breakpoint(medium) {
          display: inline-block;
          width: 100%;
        }
      }

      .work_section {
        font-size: rem-calc(12);
        color: get-color(primary);
        padding: 0 rem-calc(5);

        span {
          padding: 0;
          line-height: 1.5;
          display: inline-block;
          border-bottom: 1px solid transparent;
          margin-bottom: 2px;
        }

        &:hover {

          span {
            border-color: get-color(primary);
          }
        }

        &:before {
          top: 0;
          left: -7px;
          width: 2px;
          height: 2px;
          font-size: rem-calc(12);
        }
      }
    }
  }

  .logo_bar {
    @include flex-align(
            $x: justify
    );

    .content_cell.-half {
      @include xy-cell(6);
    }
  }

  .-submenu {
    .menu {
      display: inline-block;
      padding: 0;
      margin: 0;

      a {
        padding: 0;
        line-height: 1.5;
        display: inline-block;
        border-bottom: 1px solid transparent;
        margin-bottom: 2px;
      }

      li {
        &.is-active,
        &:hover {
          a {
            border-color: get-color(primary);
          }
        }
      }
    }
  }

  .top_title {
    margin-top: 0;

    h1 {
      font-family: 'juliettaregular';
      font-weight: 400;
      font-size: rem-calc(90);
    }
  }

  .title {
    margin-top: rem-calc(80);
    margin-bottom: rem-calc(15);

    @include breakpoint(large) {
      margin-top: rem-calc(150);
      margin-bottom: rem-calc(30);
    }

    h1 {
      font-family: 'juliettaregular';
      font-weight: 400;
      font-size: rem-calc(70);
      line-height: 1;
    }
  }
}